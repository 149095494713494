<template>
    <div>
		<CRow>
            <CCol sm="12">
                <ListForm :headerTitle="$t('pages.bank.bankListing')" :onSearch="onSearch">
                    <template v-slot:headerLeft>
                        <button type="button" name="add-bank" class="btn btn-success" @click="onAddBank">
                            {{ $t('pages.bank.addBank') }}
                        </button>
                    </template>
                    <template v-slot:searchBox>
                        <CForm>
                            <CRow>
                                <CCol md="6">
                                    <CInput :label="$t('pages.bank.bankName')" :placeholder="$t('pages.bank.enterBankName')"
                                            v-model="searchBankName" required horizontal>
                                    </CInput>
                                </CCol>
                                <CCol md="6">
                                    <CInput :label="$t('pages.bank.bankCode')" :placeholder="$t('pages.bank.enterBankCode')"
                                            v-model="searchBankCode" required horizontal>
                                    </CInput>
                                </CCol>
                            </CRow>
                        </CForm>
                    </template>
                    <template v-slot:list>
                        <CDataTable :items="bankList"
                                    :fields="fields"
                                    :noItemsView="{ noItems: $t('common.nodatafound') }"
                                    hover
                                    striped
                                    border
                                    small
                                    fixed
                                    :loading="isLoadingBanks"
                                    pagination
                                    :items-per-page="itemsPerPage"
                                    @pagination-change="onItemsPerPageChange"
                                    :items-per-page-select="{
                                label : $t('common.recordsperpage'),
                                values : [10,20,50,100]
                                }">
                            <template #footer v-if="bankList != null && bankList.length > 0">
                                <tfoot>
                                    <tr>
                                        <td :colspan="4">
                                            <span>{{ $t('common.totalRecords') }}: </span><span style="padding-left:5px;color:crimson;font-weight:bold">{{bankList == null ? 0: bankList.length}}</span>
                                        </td>
                                    </tr>
                                </tfoot>
                            </template>
                            <template #bank_detail_actions="{item, index}">
                                <td class="py-2">
                                    <CButton color="primary"
                                             variant="outline"
                                             square
                                             size="sm"
                                             @click="onEditBank(item, index)">
                                        {{ $t('common.edit') }}
                                    </CButton>
                                    <CButton color="primary"
                                             variant="outline"
                                             square
                                             size="sm"
                                             @click="onDeleteBank(item, index)">
                                        {{ $t('common.remove') }}
                                    </CButton>
                                </td>
                            </template>
                        </CDataTable>
                    </template>
                </ListForm>
                <Confirmation ref="confirmation"></Confirmation>
            </CCol>
        </CRow>
	</div>
</template>

<script>
    import i18n from '@/plugins/i18n'
    import Confirmation from '@/components/Confirmation.vue'
    import ListForm from '@/components/ListForm.vue'
    import { mapGetters, mapActions } from 'vuex'
    
    export default {
		name: 'BankList',
		data() {            
			return {                
				itemsPerPage: 10,
                warningModal: false,                          
                fields: [
                    { key: 'bankName', label: i18n.t('pages.bank.bankName') },	
                    { key: 'bankCode', label: i18n.t('pages.bank.bankCode') },	
                    { key: 'swiftCode', label: i18n.t('pages.bank.swiftCode') },							
                    {
                        key: 'bank_detail_actions',
                        label: i18n.t('common.action'),
                        _style: 'width:12%',
                        sorter: false,
                        filter: false
                    }
                ],
                searchBankName: '',
                searchBankCode: ''
            };
		},
        components: { Confirmation, ListForm },
        computed: {            
            ...mapGetters('bank', ['isLoadingBanks', 'bankList'])
        },
        methods: {
            ...mapActions('bank', ['searchBanks']),

            onDeleteBank(item) {
                this.$refs.confirmation.show(i18n.t('pages.bank.confirmDeleteBank'), async () => {
                   
                    var retVal = await this.$store.dispatch("bank/deleteBank", item.id);
                   
                    if (retVal == true)
                    {
                        this.$store.dispatch('toast/displayNotification', { text: i18n.t('pages.bank.noticeDeleteBankSuccess') }, { root: true });
                        this.onSearch();
                    }
                    else
                        this.$store.dispatch('alert/error', i18n.t('pages.bank.noticeDeleteBankFailed'), { root: true });
                });
            },
            onEditBank(item) {
                this.$router.push('/banks/edit/' + item.id);
            },
            onAddBank() {
                this.$router.push('/banks/add');
            },
            onItemsPerPageChange(item) {
                localStorage.setItem('bankItemsPerPage', item)
            },
            onSearch() {
                this.searchBanks({ bankName: this.searchBankName, bankCode: this.searchBankCode });
            }
		},
        created() {
            this.onSearch();
            
            let bankItemsPerPage = parseInt(localStorage.getItem('bankItemsPerPage'));
            if (isNaN(bankItemsPerPage))
                bankItemsPerPage = 10;
            this.itemsPerPage = bankItemsPerPage;
        }
	}
</script>